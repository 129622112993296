import Header from './Header.js';
import Hero from './Hero.js';
import LogoCarousel from './LogoCarousel.js';
import Content from './Content.js';
import Vision from './Vision.js';
import Problem from './Problem.js';
import Footer from './Footer.js';
import React, {useEffect, useState} from 'react';
import FormsSection from "./FormsSection";


const Index = () => {
    const [bgColor, setBgColor] = useState('bg-white');
    const [bgst, setbgst] = useState("");

    const handleScroll = () => {
        const scrollY = window.scrollY;
        if (scrollY < 800) {
            setBgColor('bg-[#F5F5F6]');
            setbgst("light");
        } else if (scrollY > 800 && scrollY < 3155) {
            setBgColor('bg-white');
            setbgst("light");
        } else if (scrollY > 3155) {
            setBgColor('bg-white');
            setbgst("light");

        } else {
            setBgColor('bg-white');
        }
    };
    console.log(window.scrollY)


    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div>
            <div>
                <Header/>
                <FormsSection/>
                <div>
                    <div className={`relative min-h-screen flex flex-col transition-colors duration-700 ${bgColor}`}>

                        <Vision st={bgst}/>

                        <Problem st={bgst}/>

                    </div>
                </div>
            </div>

            <Footer/>

        </div>


    )
}

export default Index;