import React, {useEffect, useState} from 'react';

const Vision = ({st}) => {
    const [stt, setstt] = useState();


    useEffect(() => {
        if (st == "dark") {
            setstt(true)
            console.log(stt)
        } else if (st == "light") {
            setstt(false)


        }
    }, [st]);
    return (
        <div className=" flex justify-center items-center mt-20 mx-20">
            <div className="flex justify-between flex-col-reverse  md:flex-row items-center gap-32   md:w-[1400px]">
                <div className="flex md:flex-row flex-col gap-4">


                    <div className="space-y-4 w-[400px] text-left max-md:px-10">
                        <details className="group [&_summary::-webkit-details-marker]:hidden" open>
                            <summary
                                className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg  p-4 text-gray-900"
                            >
                                <h2 className="font-medium text-2xl">What is our Vision?</h2>

                                <svg
                                    className="size-5 shrink-0 transition duration-300 group-open:-rotate-180"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M19 9l-7 7-7-7"/>
                                </svg>
                            </summary>

                            <p className="mt-4 px-4 leading-relaxed text-gray-700">
                                To be the leading force in precision-driven advertising, where real-time insights and
                                innovative technology empower brands to reach their fullest potential and connect
                                meaningfully with their audience.
                            </p>
                        </details>

                        <details className="group [&_summary::-webkit-details-marker]:hidden">
                            <summary
                                className="flex cursor-pointer items-center justify-between gap-1.5 rounded-lg bg-gray- p-4 text-gray-900"
                            >
                                <h2 className="font-medium text-2xl">What is our mission</h2>

                                <svg
                                    className="size-5 shrink-0 transition duration-300 group-open:-rotate-180"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                          d="M19 9l-7 7-7-7"/>
                                </svg>
                            </summary>

                            <p className="mt-4 px-4 leading-relaxed text-gray-700">
                                Our mission is to deliver high-impact, data-driven advertising solutions that combine
                                advanced technology and strategic insights, ensuring our clients’ messages reach the
                                right audience at the right time for maximum engagement and measurable results.
                            </p>
                        </details>
                    </div>


                </div>
                <img
                    className={`md:w-[510px] w-[310px]  transition-all duration-700 ${stt ? " opacity-5" : " opacity-100"}`}
                    src="/img/vision.png"></img>
            </div>
        </div>
    )
}

export default Vision;