import React from 'react';

const InfoBox = ({title, description, svgD}) => {
    return (
        <div
            // style={{border: "solid 1px", borderColor: "#D1D5DA"}}
            className="w-[550px] text-gray-500 font-medium rounded-xl bg-white p-[2rem]
             hover:bg-gray-200 transition-all ease-in-out flex flex-col items-start justify-start h-full"
        >
            <div className=" p-3 rounded-xl">
                <svg
                    className="text-black
                   "
                    width="22px"
                    height="100%"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d={svgD}
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </div>

            <div className="mt-5">
                <h3 className="text-gray-700 text-xl font-semibold text-left">{title}</h3>
                <p className="text-left text-gray-400 mt-1">{description}</p>
            </div>
        </div>
    );
};

export default InfoBox;
