import React, { useState, useEffect } from "react";

const Header = () => {
    const [atTop, setAtTop] = useState(true);
    const [open, setOpen] = useState(false);
  
    useEffect(() => {
      const handleScroll = () => {
        setAtTop(window.pageYOffset <= 50);
      };
      window.addEventListener("scroll", handleScroll);
      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);
    return (
        <header>
        <div
          className={`fixed z-50 w-full px-8 py-2 transition-all duration-1000 rounded-xl mt-4 max-w-2xl inset-x-0 mx-auto ease-in-out transform ${
            atTop ? "md:max-w-7xl max-w-[350px] bg-black" : "bg-gray-400 bg-opacity-60 backdrop-blur-xl md:max-w-7xl max-w-[390px]"
          }`}
        >
          <div className="flex flex-col w-full p-2 mx-auto md:items-center md:justify-between md:flex-row">
            <div className="flex flex-row items-center justify-between">
              <a href="/"
                className={`font-bold tracking-tighter text-white uppercase ${
                  atTop ? "text-white" : "text-white"
                }`}
              >
                <img className="w-[50px]" src="/img/logobee.png"></img>
              </a>
              <button className="md:hidden focus:outline-none" onClick={() => setOpen(!open)}>
                {/* SVG Burger goes here */}
              </button>
            </div>
            <nav
              className={`flex-col flex-grow gap-8 pb-4 md:pb-0 md:flex md:flex-row lg:ml-auto justify-end ${
                open ? "flex" : "hidden"
              }`}
            >
              <a className={atTop ? "text-white" : "text-white"} href="#_">
                About
              </a>
              <a className={atTop ? "text-white" : "text-white"} href="#_">
                Work
              </a>
              <a className={atTop ? "text-white" : "text-white"} href="#_">
                Blog
              </a>
            </nav>
          </div>
        </div>

      </header>
    )
}

export default Header;