import logo from './logo.svg';
import './App.css';
import Index from './comps/Index.js';
import Footer from './comps/Footer.js';
import F1 from './comps/F1.js';
import F2 from './comps/F2.js';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";


function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/advertisers" element={<F1 />} />
          <Route path="/drivers" element={<F2 />} />
          <Route path="/" element={<Index />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
