import React, {useEffect, useState} from 'react';
import InfoBox from "./row_item";

const Problem = ({st}) => {
    const [stt, setstt] = useState();

    useEffect(() => {
        if (st === "dark") {
            setstt(true);
        } else if (st === "light") {
            setstt(false);
        }
    }, [st]); 

    return (
        <div className="flex flex-col items-start justify-center mt-[120px] py-16 px-5 md:py-32 md:px-20 bg-gray-100">
            <h2 className={`text-4xl md:text-5xl lg:text-6xl max-w-[750px] mt-5 leading-normal text-left ${stt ? "text-white" : "text-gray-800"}`}>
                Elevate Your Brand Visibility!
            </h2>

            <p className="max-w-[550px] text-gray-500 font-medium text-left rounded-xl transition-all ease-in-out mt-4">
                Transform your advertising with our eye-catching car ads that grab attention everywhere!
            </p>

            <div className="overflow-hidden md:overflow-x-auto mt-10 md:mt-16 w-full">
                <div className="flex space-x-6 md:space-x-10 overflow-x-auto scrollbar-hide">
                    <div className="min-w-[300px] flex-shrink-0">
                        <InfoBox
                            title={"Maximum Exposure"}
                            description={
                                <p className="text-left text-gray-400 max-md:max-w-[300px]">
                                    Your brand on high-traffic vehicles, strategically placed for maximum exposure,
                                    ensuring your message reaches your target.
                                </p>
                            }
                            svgD={"M15.5 3.5V2M19.4393 4.56066L20.5 3.5M20.5103 8.5H22.0103M21.9506 13C21.4489 18.0533 17.1853 22 12 22C6.47715 22 2 17.5228 2 12C2 6.81465 5.94668 2.5511 11 2.04938M12 8H16V12M15.6197 8C13.2653 11.3276 9.38636 13.5 5 13.5C3.9971 13.5 3.02072 13.3864 2.08302 13.1715"}
                        />
                    </div>
                    <div className="min-w-[300px] flex-shrink-0">
                        <InfoBox
                            title={"Cost-Effective"}
                            description={
                                <p className="text-left text-gray-400 max-md:max-w-[300px]">
                                    We offer more than impact – we provide affordability. Our vehicle advertising is one
                                    of the most cost-effective solutions available.
                                </p>
                            }
                            svgD={"M3.41345 10.7445C2.81811 10.513 2.52043 10.3972 2.43353 10.2304C2.35819 10.0858 2.35809 9.91354 2.43326 9.76886C2.51997 9.60195 2.8175 9.48584 3.41258 9.25361L20.3003 2.66327C20.8375 2.45364 21.1061 2.34883 21.2777 2.40616C21.4268 2.45596 21.5437 2.57292 21.5935 2.72197C21.6509 2.8936 21.5461 3.16219 21.3364 3.69937L14.7461 20.5871C14.5139 21.1822 14.3977 21.4797 14.2308 21.5664C14.0862 21.6416 13.9139 21.6415 13.7693 21.5662C13.6025 21.4793 13.4867 21.1816 13.2552 20.5862L10.6271 13.8282C10.5801 13.7074 10.5566 13.647 10.5203 13.5961C10.4881 13.551 10.4487 13.5115 10.4036 13.4794C10.3527 13.4431 10.2923 13.4196 10.1715 13.3726L3.41345 10.7445Z"}
                        />
                    </div>
                    <div className="min-w-[300px] flex-shrink-0">
                        <InfoBox
                            title={"Risk-Free Guarantee"}
                            description={
                                <p className="text-left text-gray-400 max-md:max-w-[300px]">
                                    And here's the kicker – it's risk-free. Try our service, and if you're not delighted
                                    within the first month...
                                </p>
                            }
                            svgD={"M21 21H4.6C4.03995 21 3.75992 21 3.54601 20.891C3.35785 20.7951 3.20487 20.6422 3.10899 20.454C3 20.2401 3 19.9601 3 19.4V3M21 7L15.5657 12.4343C15.3677 12.6323 15.2687 12.7313 15.1545 12.7684C15.0541 12.8011 14.9459 12.8011 14.8455 12.7684C14.7313 12.7313 14.6323 12.6323 14.4343 12.4343L12.5657 10.5657C12.3677 10.3677 12.2687 10.2687 12.1545 10.2316C12.0541 10.1989 11.9459 10.1989 11.8455 10.2316C11.7313 10.2687 11.6323 10.3677 11.4343 10.5657L7 15"}
                        />
                    </div>
                    <div className="min-w-[300px] flex-shrink-0">
                        <InfoBox
                            title={"Tailored Campaigns"}
                            description={
                                <p className="text-left text-gray-400 max-md:max-w-[300px]">
                                    Tailor your message to perfection by choosing your area of interest and target
                                    location.
                                </p>
                            }
                            svgD={"M9 12H4.6C4.03995 12 3.75992 12 3.54601 12.109C3.35785 12.2049 3.20487 12.3578 3.10899 12.546C3 12.7599 3 13.0399 3 13.6V19.4C3 19.9601 3 20.2401 3.10899 20.454C3.20487 20.6422 3.35785 20.7951 3.54601 20.891C3.75992 21 4.03995 21 4.6 21H9M9 21H15M9 21L9 8.6C9 8.03995 9 7.75992 9.10899 7.54601C9.20487 7.35785 9.35785 7.20487 9.54601 7.10899C9.75992 7 10.0399 7 10.6 7H13.4C13.9601 7 14.2401 7 14.454 7.10899C14.6422 7.20487 14.7951 7.35785 14.891 7.54601C15 7.75992 15 8.03995 15 8.6V21M15 21H19.4C19.9601 21 20.2401 21 20.454 20.891C20.6422 20.7951 20.7951 20.6422 20.891 20.454C21 20.2401 21 19.9601 21 19.4V13.6C21 13.0399 21 12.7599 20.891 12.546C20.7951 12.3578 20.6422 12.2049 20.454 12.109C20.2401 12 19.9601 12 19.4 12H15"}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Problem;
