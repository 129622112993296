import React from "react";

function App() {
    return (
        <div className="h-[700px] md:h-[600px]">
            <section className="h-full flex flex-col lg:flex-row">
                {/* Driver Box */}
                <a
                    className="flex-1 group bg-cover relative flex items-end justify-start mr-1 bg-gray-300
                    text-white text-2xl font-bold  transition-transform transform hover:cale-x-105 hover:bg-primary"
                href={'/drivers'} style={{backgroundImage: `url("/img/driver.jpg")` , backgroundRepeat: 'no-repeat',}}>
                    <div className="w-full h-full bg-gray-900 opacity-40 absolute group-hover:opacity-0 transition-all  "></div>
                    <span className="pb-10 md:pl-10 pl-3 text-3xl">
                    Are you a driver?
                    </span>
                    <svg
                        className="absolute bottom-12 right-12 w-8 h-8 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 4l8 8-8 8M4 12h16"
                        />
                    </svg> 
                </a>

                {/* Business Owner Box */}
                <a
                    className="flex-1 group bg-cover relative flex items-end justify-start mr-1 bg-gray-300
                    text-white text-2xl font-bold  transition-transform transform hover:cale-x-105 hover:bg-primary"
                href={'/advertisers'} style={{backgroundImage: `url("/img/bs.jpeg")` , backgroundRepeat: 'no-repeat',}}>
                    <div className="w-full h-full bg-gray-900 opacity-40 absolute group-hover:opacity-0 transition-all  "></div>
                    <span className="pb-10 md:pl-10 pl-3 text-3xl text-left">
                    Are you a <br></br> business owner?
                    </span>
                    <svg
                        className="absolute bottom-12 right-12 w-8 h-8 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 4l8 8-8 8M4 12h16"
                        />
                    </svg>
                </a>
            </section>
        </div>
    );
}

export default App;
